import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getIdTokenResult } from 'firebase/auth';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import { auth, db } from './firebase';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'Users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUser(user);
            setIsAdmin(userData.role === 'admin');
          } else {
            console.log('No user data found.');
            setUser(null);
            setIsAdmin(false);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUser(null);
          setIsAdmin(false);
        }
      } else {
        setUser(null);
        setIsAdmin(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      {user && isAdmin ?  <Dashboard /> : <Login setUser={setUser} />}
    </div>
  );
}

export default App;
