import React from 'react';
import { List, ListItem, ListItemText, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useState } from 'react';
import { updateClientStatus } from './firebaseUtils';
import '../Styles/utilStyles.css';

const statuses = ['Active', 'Inactive', 'Prospective', 'On Hold'];

const ClientList = ({ clients, onSelectClient }) => {
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [status, setStatus] = useState('');
  const [sortedClients, setSortedClients] = useState(clients);
  const [sortOrder, setSortOrder] = useState('asc');

  const sortClientsByStatus = () => {
    const sorted = [...clients].sort((a, b) => {
      const statusA = a.status || ''; // Fallback to an empty string if status is undefined
      const statusB = b.status || ''; // Fallback to an empty string if status is undefined
  
      if (sortOrder === 'asc') {
        return statusA.localeCompare(statusB);
      } else {
        return statusB.localeCompare(statusA);
      }
    });
    setSortedClients(sorted);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  

  const handleStatusChange = async (clientId, event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    await updateClientStatus(clientId, newStatus);
    setSelectedClientId(null); // Optionally close the dropdown after selection
  };

  return (
    <div>
      <button className="sortBy" onClick={sortClientsByStatus}>
        Sort by Status ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
      </button>
      <List>
        {sortedClients.map(client => (
          <ListItem button key={client.id} onClick={() => onSelectClient(client)}>
            <ListItemText primary={client.name} secondary={`Email: ${client.email}`} />
            <FormControl variant="outlined" style={{ minWidth: 120 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={selectedClientId === client.id ? status : client.status}
                onChange={(event) => handleStatusChange(client.id, event)}
                onClick={() => setSelectedClientId(client.id)}
              >
                {statuses.map(statusOption => (
                  <MenuItem key={statusOption} value={statusOption}>
                    {statusOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default ClientList;
