import react, { useState, useEffect } from 'react';
import { getFirestore, doc, updateDoc, getDoc, arrayUnion, arrayRemove, query, where, collection, getDocs, addDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getStorage } from 'firebase/storage';
import '../Styles/controlStyle.css';
import AdminProgramForm from './AdminProgramForm';

function Control() {
    const [exercisesName, setExerciseName] = useState("");
    const [exerciseInstructions, setExerciseInstructions] = useState("");
    const [exerciseTags, setExerciseTags] = useState([]);
    const [exerciseVideo, setExerciseVideo] = useState(null);
    const [editingExerciseId, setEditingExerciseId] = useState(null);

    const [playlistName, setPlaylistName] = useState("");
    const [playlistThumbnail, setPlaylistThumbnail] = useState(null);
    const [playlistExercises, setPlaylistExercises] = useState([]);
    const [allExercises, setAllExercises] = useState([]);
    const [filteredExercises, setFilteredExercises] = useState([]);
    const [editingPlaylistId, setEditingPlaylistId] = useState(null);

    const [exercises, setExercises] = useState([]);
    const [playlists, setPlaylists] = useState([]); 

    const [showExerciseForm, setShowExerciseForm] = useState(false);
    const [showPlaylistForm, setShowPlaylistForm] = useState(false); 

    const [searchQuery, setSearchQuery] = useState('');

    const db = getFirestore();
    const storage = getStorage();

    const fetchPlaylists = async () => {
        const playlistsSnapshot = await getDocs(collection(db, 'adminPlaylists'));
        const playlistsData = playlistsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPlaylists(playlistsData);
        console.log(playlistsData);
    };

    const fetchExercises = async () => {
        const exercisesSnapshot = await getDocs(collection(db, 'exercises'));
        const exercisesData = exercisesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setExercises(exercisesData);
        setFilteredExercises(exercisesData); 
    };

    //Fetch all playlists from adminPlaylists & all exercises
    useEffect(() => {
        fetchPlaylists();
        fetchExercises();
    }, []);

    //Create playlists which can be pushed live
    const createPlaylist = async () => {
        try {
            // Upload thumbnail if a file is selected
            let thumbnailUrl = "";
            if (playlistThumbnail) {
                const thumbnailRef = ref(storage, `Playlists/${playlistName}_thumbnail.jpg`);
                await uploadBytes(thumbnailRef, playlistThumbnail);
                thumbnailUrl = await getDownloadURL(thumbnailRef);
            }
    
            // Add playlist data to Firestore
            const playlistRef = await addDoc(collection(db, 'adminPlaylists'), {
                name: playlistName,
                thumbnailUrl: thumbnailUrl, // Store the thumbnail URL
                exercises: playlistExercises, // Store selected exercise IDs
                live: false // Initialize with live status as false
            });
    
            console.log("Playlist created with name:", playlistName, "and thumbnail URL:", thumbnailUrl);
        } catch (error) {
            console.error("Error creating playlist:", error);
        }
    };    

    //Push live functionality
    const pushPlaylistLive = async (playlistId) => {
        const playlistRef = doc(db, 'adminPlaylists', playlistId);
        await updateDoc(playlistRef, {
            live: true
        });

        console.log("Playlist pushed live and copied to users.");
        await fetchPlaylists();
    };

    //Remove playlist
    const removePlaylistLive = async (playlistId) => {
        try {
            // Update the playlist to set live to false
            const playlistRef = doc(db, 'adminPlaylists', playlistId);
            await updateDoc(playlistRef, {
                live: false
            });

            await fetchPlaylists();
        } catch (error) {
            console.error("Error removing playlist from live:", error);
        }
    };    

    const handleExerciseSubmit = async (event) => {
        event.preventDefault(); // Prevent the form from refreshing the page
        await uploadExercise(exercisesName, exerciseInstructions, exerciseVideo, exerciseTags);
        setShowExerciseForm(false); // Close the form after submission
        fetchExercises(); // Refresh the exercises list
    };
    
    const handlePlaylistSubmit = async (event) => {
        event.preventDefault(); // Prevent the form from refreshing the page
        await createPlaylist();
        setShowPlaylistForm(false); // Close the form after submission
        fetchPlaylists(); // Refresh the playlists list
    };

    const uploadExercise = async (exerciseName, instructions, videoFile, tags) => {
        try {
            if (!videoFile) {
                console.error("No video file selected");
                return;
            }
    
            // Step 1: Extract thumbnail from the video
            const thumbnailBlob = await extractThumbnailFromVideo(videoFile);
            if (!thumbnailBlob) {
                console.error("Failed to extract thumbnail");
                return;
            }
    
            // Step 2: Upload thumbnail to Firebase Storage
            const thumbnailRef = ref(storage, `Images/${exerciseName}_thumbnail.jpg`);
            await uploadBytes(thumbnailRef, thumbnailBlob);
            const thumbnailUrl = await getDownloadURL(thumbnailRef);
            console.log("Thumbnail uploaded: ", thumbnailUrl);
    
            // Step 3: Upload video to Firebase Storage
            const videoRef = ref(storage, `Videos/${exerciseName}_video.mp4`);
            await uploadBytes(videoRef, videoFile);
            const videoUrl = await getDownloadURL(videoRef);
            console.log("Video uploaded: ", videoUrl);
    
            // Step 4: Add exercise data to Firestore
            const exerciseData = {
                name: exerciseName,
                instructions: instructions,
                videoUrl: videoUrl,
                imageUrl: thumbnailUrl,
                tags: tags.split(',').map(tag => tag.trim()) // Split tags by commas
            };
            
            const exerciseRef = await addDoc(collection(db, "exercises"), exerciseData);
            console.log("Exercise added to Firestore with ID: ", exerciseRef.id);
    
        } catch (error) {
            console.error("Error uploading exercise: ", error);
        }
    };
    
    // Helper function to extract thumbnail from the video
    const extractThumbnailFromVideo = (videoFile) => {
        return new Promise((resolve, reject) => {
            const videoElement = document.createElement("video");
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
    
            videoElement.src = URL.createObjectURL(videoFile);
    
            // Set up the video event listeners
            videoElement.addEventListener("loadeddata", () => {
                // Ensure the video has loaded and can be processed
                videoElement.currentTime = 1; // Seek to 1 second for the thumbnail
            });
    
            videoElement.addEventListener("seeked", () => {
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
    
                // Draw the current frame onto the canvas
                context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    
                // Convert the canvas to a Blob
                canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        reject(new Error("Failed to convert canvas to Blob"));
                    }
                }, "image/jpeg");
            });
    
            videoElement.addEventListener("error", (error) => {
                console.error("Error loading video for thumbnail extraction", error);
                reject(error);
            });
    
            // Clean up the video object URL after use
            videoElement.addEventListener("loadedmetadata", () => {
                URL.revokeObjectURL(videoElement.src);
            });
        });
    };

    //Remove exercise
    const removeExercises = async (exerciseId) => {
        try {
          // Reference to the exercise document
          const exerciseRef = doc(db, "exercises", exerciseId);
      
          // Delete the document
          await deleteDoc(exerciseRef);
      
          // Optionally, refresh or update the UI by refetching exercises
          console.log(`Exercise with ID ${exerciseId} deleted successfully.`);
          fetchExercises();
        } catch (error) {
          console.error("Error deleting exercise: ", error);
        }
    };

    const addExerciseToPlaylist = (exerciseId) => {
        setPlaylistExercises(prev => [...prev, exerciseId]);
    };    

    const removeExerciseFromPlaylist = (exerciseId) => {
        setPlaylistExercises(prev => prev.filter(id => id !== exerciseId));
    };

    useEffect(() => {
        if (searchQuery) {
            const filtered = exercises.filter(exercise =>
                exercise.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredExercises(filtered);
        } else {
            setFilteredExercises(exercises); // If no search query, show all exercises
        }
    }, [searchQuery, exercises]);

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const deletePlaylist = async (playlistId) => {
        const confirmed = window.confirm("Are you sure you want to delete this playlist?");
        if (!confirmed) return;
    
        try {
            // Delete the playlist from Firestore
            await deleteDoc(doc(db, 'adminPlaylists', playlistId));
            console.log("Playlist deleted with ID:", playlistId);
            
            // Refresh the playlists list
            await fetchPlaylists();
        } catch (error) {
            console.error("Error deleting playlist:", error);
        }
    };    

    const editExercise = async (exerciseId) => {
        const docRef = doc(db, 'exercises', exerciseId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            setExerciseName(data.name);
            setExerciseInstructions(data.instructions);
            setExerciseTags(data.tags.join(', '));
            setEditingExerciseId(exerciseId);
            setShowExerciseForm(true);
        }
    };

    const editPlaylist = async (playlistId) => {
        const docRef = doc(db, 'adminPlaylists', playlistId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            setPlaylistName(data.name);
            setPlaylistExercises(data.exercises);
            setEditingPlaylistId(playlistId);
            setShowPlaylistForm(true);
        }
    };

    return (
        <div className="controlPanelContainer">
            <h2>Control Panel</h2>
            <div className="exerciseContainer">
                <div className="horizontalHeading">
                    <h3>Exercise</h3>
                    <button onClick={() => setShowExerciseForm(true)}>+</button>
                </div>

                <div className="exercisesList">
                    {exercises.sort((a, b) => a.name.localeCompare(b.name)).map(exercise => (
                        <div className="fetchedExercisesContainer" key={exercise.id}>
                            <img
                                        src={exercise.imageUrl}
                                        alt={`${exercise.name} thumbnail`}
                                        className="exerciseThumbnail"
                            />
                            <div>
                                <h4>{exercise.name}</h4>
                                <p>
                                    {exercise.tags && exercise.tags.length > 0
                                                ? exercise.tags.join(' • ')
                                                : 'No tags available'}
                                </p>
                            </div>
                            <div className="exerciseBtns">
                                <button onClick={() => editExercise(exercise.id)}>Edit</button>
                                <button onClick={() => removeExercises(exercise.id)}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="playlistsContainer">
                <div className="horizontalHeading">
                    <h3>Playlists</h3>
                    <button onClick={() => setShowPlaylistForm(true)}>+</button>
                </div>

                <div className="playlistsList">
                    {playlists.map(playlist => (
                        <div className="fetchedPlaylist" key={playlist.id}>
                            <img
                                        src={playlist.thumbnailUrl}
                                        alt={`${playlist.name} thumbnail`}
                                        className="playlistThumbnail"
                            />

                            <div className="fetchedPlaylistText">
                                <h4>{playlist.name}</h4>
                                <p style={{ color: playlist.live ? 'green' : 'red' }}>Live: {playlist.live ? 'True' : 'False'}</p>
                            </div>
                            
                            <div className="playlistBtns">
                                <button onClick={() => playlist.live ? removePlaylistLive(playlist.id) : pushPlaylistLive(playlist.id)}>
                                    {playlist.live ? 'Remove Live' : 'Push Live'}
                                </button>
                                <button onClick={() => editPlaylist(playlist.id)}>Edit</button>
                                <button onClick={() => deletePlaylist(playlist.id)}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            
            <div className="adminProgramContainer">
                <AdminProgramForm />
            </div>

            {showExerciseForm && (
                <div className="modal">
                    <form onSubmit={handleExerciseSubmit}>
                        <h3>Add Exercise</h3>
                        <div>
                            Name:
                            <input type="text" value={exercisesName} onChange={(e) => setExerciseName(e.target.value)} required />
                        </div>
                        <div>
                            Instructions:
                            <textarea value={exerciseInstructions} onChange={(e) => setExerciseInstructions(e.target.value)} required />
                        </div>
                        <div>
                            Tags (comma separated):
                            <input type="text" value={exerciseTags} onChange={(e) => setExerciseTags(e.target.value)} required />
                        </div>
                        <div>
                            Video:
                            <input type="file" onChange={(e) => setExerciseVideo(e.target.files[0])} accept="video/*" required />
                        </div>

                        <div className="modalBtns">
                            <button type="submit">Submit</button>
                            <button type="button" onClick={() => setShowExerciseForm(false)}>Cancel</button>
                        </div>
                        
                    </form>
                </div>
            )}
            {showPlaylistForm && (
                <div className="modal">
                    <form onSubmit={handlePlaylistSubmit}>
                        <h3>Add Playlist</h3>
                        <label>
                            Name:
                            <input type="text" value={playlistName} onChange={(e) => setPlaylistName(e.target.value)} required />
                        </label>
                        <div>
                            Thumbnail:
                            <input type="file" onChange={(e) => setPlaylistThumbnail(e.target.files[0])} accept="image/*" required />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="Search exercises to add"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="searchResults">
                            {filteredExercises.map(exercise => (
                                <div className="searchContainer" key={exercise.id}>
                                    <img
                                        src={exercise.imageUrl}
                                        alt={`${exercise.name} thumbnail`}
                                        className="exerciseThumbnail"
                                    />
                                    <div className="searchText">
                                        <h3>{exercise.name}</h3>
                                        <button type="button" onClick={() => addExerciseToPlaylist(exercise.id)}>Add to Playlist</button>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="modalBtns">
                            <button type="submit">Submit</button>
                            <button type="button" onClick={() => setShowPlaylistForm(false)}>Cancel</button>
                        </div>
                    </form>
                    <h4>Added Exercises</h4>
                    <ul className="storedExercisesContainer">
                        {playlistExercises.map(exerciseId => {
                            const exercise = exercises.find(e => e.id === exerciseId);
                            return (
                                <li className="storedExercises" key={exerciseId}>
                                    {exercise ? exercise.name : `Exercise ID: ${exerciseId}`}
                                    <button onClick={() => removeExerciseFromPlaylist(exerciseId)}>Remove</button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}

            <div className="screener" style={{ display: showExerciseForm ? 'block' : 'none'}}></div>
            <div className="screener" style={{ display: showPlaylistForm ? 'block' : 'none'}}></div>
        </div>
    )
}

export default Control;