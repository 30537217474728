import react, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Line } from 'react-chartjs-2'; // You can use Chart.js or another chart library
import 'chart.js/auto';
import '../Styles/analyticStyle.css';

function Analytics() {
    const [monthlyUserCounts, setMonthlyUserCounts] = useState([]);
    const [projectedUserCounts, setProjectedUserCounts] = useState([]);
    const [months, setMonths] = useState([]);

    const db = getFirestore();

    // Fetch users data from Firestore
    useEffect(() => {
        const fetchUserSignUpData = async () => {
            try {
                const response = await fetch('https://yzumtqxkjh.ap-southeast-2.awsapprunner.com/get-user-signup-data');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data)

                processUserSignUpData(data);
            } catch (error) {
                console.error('Error fetching user sign-up data:', error);
            }
        };

        fetchUserSignUpData();
    }, [db]);

    const processUserSignUpData = (data) => {
        const monthCounts = {};

        // Process each entry
        Object.entries(data).forEach(([key, value]) => {
            const [monthName, year] = key.split(' ');
            const formattedMonth = `${monthName} ${year}`;
    
            if (monthCounts[formattedMonth]) {
                monthCounts[formattedMonth] = value;
            } else {
                monthCounts[formattedMonth] = value;
            }
        });

        // Prepare data for the chart
        let sortedMonths = Object.keys(monthCounts).sort((a, b) => {
            // Sort months chronologically
            const [monthA, yearA] = a.split(' ');
            const [monthB, yearB] = b.split(' ');
            return new Date(`${monthA} 1, ${yearA}`) - new Date(`${monthB} 1, ${yearB}`);
        });

        // Cap the months to the last 6 months
        const currentDate = new Date();
        const lastSixMonths = [];
        for (let i = 0; i < 6; i++) {
            const year = currentDate.getFullYear();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const monthName = new Date(year, currentDate.getMonth()).toLocaleString('default', { month: 'short' });
            lastSixMonths.unshift(`${monthName} ${year}`); // Insert at the beginning of the array
            currentDate.setMonth(currentDate.getMonth() - 1);
        }

        // Ensure at least 6 months are displayed
        const historicalData = lastSixMonths.filter(month => sortedMonths.includes(month));
        const historicalCounts = historicalData.map(month => monthCounts[month] || 0);

        // Project the next 6 months
        const projectedData = projectNextSixMonths(historicalCounts);
        const futureMonthsList = generateFutureMonths(6);

        // Combine historical and projected months
        const combinedMonths = [...historicalData, ...futureMonthsList];
        const combinedCounts = [...historicalCounts, ...projectedData];

        setMonths(combinedMonths);
        setMonthlyUserCounts(combinedCounts);
        setProjectedUserCounts(projectedData);
    };

    const projectNextSixMonths = (counts) => {
        const averageGrowth = counts.length > 1
            ? (counts[counts.length - 1] - counts[0]) / (counts.length - 1)
            : 0;

        const projected = [];
        for (let i = 0; i < 6; i++) {
            const newCount = counts[counts.length - 1] + averageGrowth * (i + 1);
            projected.push(newCount > 0 ? Math.round(newCount) : 0);
        }

        return projected;
    };

    // Function to generate future months
    const generateFutureMonths = (numMonths) => {
        const futureMonths = [];
        const currentDate = new Date();

        for (let i = 0; i < numMonths; i++) {
            currentDate.setMonth(currentDate.getMonth() + 1);
            const year = currentDate.getFullYear();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const monthName = new Date(year, currentDate.getMonth()).toLocaleString('default', { month: 'short' });
            futureMonths.push(`${monthName} ${year}`);
        }

        return futureMonths;
    };

    const data = {
        labels: months,
        datasets: [
            {
                label: 'Number of Users',
                data: monthlyUserCounts.concat(projectedUserCounts),
                fill: false,
                borderColor: 'rgba(75,192,192,1)',
                backgroundColor: 'rgba(75,192,192,0.2)',
                tension: 0.1,
                borderDash: [5, 5], 
            },
        ],
    };

    return (
        <div>
            <h2>Analytics</h2>
            <div className="chart-container">
                <h3>Month-by-Month Growth</h3>
                <Line data={data} options={{
                    responsive: true,
                    maintainAspectRatio: false,
                }}/>
            </div>
        </div>
    )
}

export default Analytics;