import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import '../Styles/utilStyles.css';

function ExerciseSelectionModal({ day, onSave, onClose }) {
  const [exercises, setExercises] = useState([]);
  const [selectedExercises, setSelectedExercises] = useState([]);

  const db = getFirestore();

  // Fetch exercises from Firestore when the component is mounted
  useEffect(() => {
    const fetchExercises = async () => {
      const exercisesCollection = collection(db, "exercises"); // Get the 'exercises' collection
      const snapshot = await getDocs(exercisesCollection); // Fetch the documents
      const fetchedExercises = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setExercises(fetchedExercises);
    };

    fetchExercises();
  }, [db]);

  // Handle selecting/deselecting an exercise
  const handleExerciseSelect = (exercise) => {
    setSelectedExercises((prevSelected) => {
      if (prevSelected.find((e) => e.id === exercise.id)) {
        // If the exercise is already selected, deselect it
        return prevSelected.filter((e) => e.id !== exercise.id);
      } else {
        // Otherwise, add it to the selected exercises
        return [...prevSelected, exercise];
      }
    });
  };

  // Handle saving selected exercises for the specific day
  const handleSave = () => {
    onSave(selectedExercises, day);
  };

  return (
    <div className="modal">
      <h3>Select Exercises for {day}</h3>
      <ul>
        {/* Display list of available exercises */}
        {exercises.map((exercise) => (
          <li key={exercise.id}>
            <label>
              <input
                type="checkbox"
                checked={selectedExercises.some((e) => e.id === exercise.id)}
                onChange={() => handleExerciseSelect(exercise)}
              />
              {exercise.name}
            </label>
          </li>
        ))}
      </ul>

      <button onClick={handleSave}>Save Selection</button>
      <button onClick={onClose}>Cancel</button>
    </div>
  );
}

export default ExerciseSelectionModal;
