import React, { useState, useEffect } from 'react';
import { MenuItem, Select, InputLabel, FormControl, TextField, Button } from '@mui/material';
import { updateDealStatus, addNoteWithVersion, fetchNoteHistory } from './firebaseUtils';
import { formatCurrency } from './utils';

const statuses = ['Pending', 'In Progress', 'Completed', 'Closed'];

const DealDetails = ({ deal }) => {
  const [status, setStatus] = useState(deal.status);
  const [note, setNote] = useState(deal.notes || '');
  const [history, setHistory] = useState([]);

  useEffect(() => {
    if (deal) {
        setStatus(deal.status);
        setNote(deal.notes || '');
    }

    const fetchHistory = async () => {
      const history = await fetchNoteHistory('deals', deal.id);
      setHistory(history);
    };
    fetchHistory();
  }, [deal.id]);

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleSaveNote = async () => {
    await addNoteWithVersion('deals', deal.id, note);
    const updatedHistory = await fetchNoteHistory('deals', deal.id);
    setHistory(updatedHistory);
  };

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    await updateDealStatus(deal.id, newStatus);
  };

  return (
    <div>
      <h3>Deal Details</h3>
      <p><strong>Name:</strong> {deal.name}</p>
      <p><strong>Amount:</strong> {formatCurrency(deal.amount)}</p>
      <p><strong>Description:</strong> {deal.description}</p>
      <FormControl fullWidth margin="normal">
        <InputLabel>Status</InputLabel>
        <Select value={status} onChange={handleStatusChange}>
          {statuses.map((statusOption) => (
            <MenuItem key={statusOption} value={statusOption}>
              {statusOption}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Notes"
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={note}
        onChange={handleNoteChange}
      />
      <Button variant="contained" onClick={handleSaveNote} style={{marginBlock: '20px'}}>
        Save Note
      </Button>
    </div>
  );
};

export default DealDetails;
