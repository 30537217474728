import react, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import '../Styles/loginStyle.css'
import { auth } from '../firebase';

function Login({ setUser }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const auth = getAuth();

    const login = async (e) => {
        e.preventDefault();
        setError('');

        try {
            // Step 1: Authenticate the user with Firebase Authentication
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = auth.currentUser;

            setUser(user);
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <div>
            <div className="login">
                <h2>Welcome! Let's Login</h2>
                <form onSubmit={login}>
                    <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
                    <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required />
                    <div className="buttonContainer">
                        <button type="submit">Login</button>
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </form>
            </div>

        </div>
    )
}

export default Login;