import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { getFirestore, collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import ExerciseSelectionModal from "./ExerciseSelectionModal";
import '../Styles/programStyle.css';

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

function AdminProgramForm() {
  const [programs, setPrograms] = useState([]); // State to hold all programs
  const [programName, setProgramName] = useState("");
  const [selectedExercises, setSelectedExercises] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentDay, setCurrentDay] = useState(null);
  const [currentProgramId, setCurrentProgramId] = useState(null); // Track the current program ID for editing
  const [showForm, setShowForm] = useState(false);
  const [expandedProgramId, setExpandedProgramId] = useState(null);

  const db = getFirestore();

  // Fetch existing programs from Firestore when the component mounts
  useEffect(() => {
    const fetchPrograms = async () => {
      const programsCollection = collection(db, "adminPrograms");
      const snapshot = await getDocs(programsCollection);
      const fetchedPrograms = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPrograms(fetchedPrograms);
    };

    fetchPrograms();
  }, [db]);

  // Open the exercise selection modal
  const handleAddExercise = (day, programId) => {
    setCurrentDay(day);
    setCurrentProgramId(programId); // Set current program ID for updates
    setShowModal(true);
  };

  // Save the selected exercises for the day
  const handleSaveExerciseSelection = (exercises, day) => {
    setSelectedExercises((prevState) => ({
      ...prevState,
      [day]: exercises,
    }));
    setShowModal(false); // Close the modal after saving
  };

  // Handle form submission to save program in Firestore
  const handleSubmit = async (e) => {
    e.preventDefault();

    const adminProgram = {
      name: programName,
      exercises: selectedExercises,
    };

    try {
      // Use `addDoc` to add the new document to Firestore
      const adminProgramsCollection = collection(db, "adminPrograms");
      await addDoc(adminProgramsCollection, adminProgram);
      alert("Admin Program created successfully!");

      // Clear the form after successful submission
      setProgramName("");
      setSelectedExercises({});
    } catch (error) {
      console.error("Error creating admin program: ", error);
    }
  };

  // Function to update a program's exercises
  const handleUpdateProgram = async (programId) => {
    const programRef = doc(db, "adminPrograms", programId);
    const updatedProgram = {
      ...programs.find((program) => program.id === programId), // Find the existing program
      exercises: selectedExercises,
    };

    try {
      await updateDoc(programRef, updatedProgram);
      alert("Program updated successfully!");
    } catch (error) {
      console.error("Error updating program: ", error);
    }
  };

  const handleDeleteProgram = async (programId) => {
    try {
      const programRef = doc(db, "adminPrograms", programId);
      await deleteDoc(programRef);
      alert("Program deleted successfully!");

      // Remove deleted program from state
      setPrograms((prevPrograms) => prevPrograms.filter(program => program.id !== programId));
    } catch (error) {
      console.error("Error deleting program: ", error);
    }
  };

  const handleToggleLive = async (programId) => {
    const programRef = doc(db, "adminPrograms", programId);
    const programToUpdate = programs.find((program) => program.id === programId);

    const updatedProgram = {
      ...programToUpdate,
      live: !programToUpdate.live, // Toggle the live state
    };

    try {
      await updateDoc(programRef, updatedProgram);
      alert(`Program ${updatedProgram.live ? "pushed live" : "taken offline"} successfully!`);
      setPrograms((prevPrograms) =>
        prevPrograms.map((program) => (program.id === programId ? updatedProgram : program))
      );
    } catch (error) {
      console.error("Error toggling live state: ", error);
    }
  };

  // Function to delete exercises from a program
  const handleDeleteExercise = (day, exerciseId) => {
    setSelectedExercises((prevState) => ({
      ...prevState,
      [day]: prevState[day].filter(exercise => exercise.id !== exerciseId),
    }));
  };

  const toggleProgramDetails = (programId) => {
    setExpandedProgramId(expandedProgramId === programId ? null : programId);
  };

  const resetForm = () => {
    setProgramName("");
    setSelectedExercises({});
    setShowForm(false); // Optionally hide the form
  };

  return (
    <div>
      <h2>
        Admin Program
        <button className="addbutton" onClick={() => setShowForm(!showForm)}>+</button>
      </h2>

      {showForm && (
        <form className="programForm" onSubmit={handleSubmit}>
          <label>
            Program Name:
            <input
              type="text"
              value={programName}
              onChange={(e) => setProgramName(e.target.value)}
              required
            />
          </label>

          {/* Iterate over each day of the week */}
          {daysOfWeek.map((day) => (
            <div key={day}>
              <div className="horizontalDisplay">
                <h4>{day}</h4>
                <button type="button" onClick={() => handleAddExercise(day, currentProgramId)}>
                  +
                </button>
              </div>

              {selectedExercises[day]?.length > 0 && (
                <ul>
                  {selectedExercises[day].map((exercise) => (
                    <li key={exercise.id}>
                      {exercise.name}
                      <button onClick={() => handleDeleteExercise(day, exercise.id)}>Remove</button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}

          <div className="btnContainer">
            <button type="submit">Save Program</button>
            <button type="button" onClick={resetForm}>Cancel</button>
          </div>
        </form>
      )}
      
      {programs.map((program) => (
        <div className="adminProgramContainer" key={program.id}>
          <h4 className="programContainer">
            <button className="expandBtn" onClick={() => toggleProgramDetails(program.id)}>
              {program.name}
            </button>
            <button onClick={() => handleToggleLive(program.id)}>
              {program.live ? "Take Offline" : "Push Live"}
            </button>
            <button onClick={() => handleDeleteProgram(program.id)}>Delete</button>
          </h4>
          {expandedProgramId === program.id && (
            <div>
              {daysOfWeek.map((day) => (
                <div key={day}>
                  <div className="btnContainer">
                    <h5>{day}</h5>
                    <button type="button" onClick={() => handleAddExercise(day, program.id)}>+</button>
                  </div>
                  {program.exercises[day]?.length > 0 && (
                    <ul className="programExercisesContainer">
                      {program.exercises[day].map((exercise) => (
                        <li key={exercise.id}>
                          {exercise.name}
                          <button onClick={() => handleDeleteExercise(day, exercise.id)}>Remove</button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      {/* Modal for exercise selection */}
      {showModal && (
        <ExerciseSelectionModal
          day={currentDay}
          onSave={handleSaveExerciseSelection}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

export default AdminProgramForm;
