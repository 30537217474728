// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAPb6XH7Zb73Ir6e5YUaO-ZR13NxAJ6JWE",
  authDomain: "fitnessapp-8e388.firebaseapp.com",
  projectId: "fitnessapp-8e388",
  storageBucket: "fitnessapp-8e388.appspot.com",
  messagingSenderId: "1057392262234",
  appId: "1:1057392262234:web:325f24346ef35712562c63"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
export default app;