import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Tabs, Tab, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DealList from './DealList';
import ClientList from './ClientList';
import DealDetails from './DealDetails';
import ClientDetails from './ClientDetails';
import AddDealForm from './AddDealForm';
import AddClientForm from './AddClientForm';
import { fetchDeals, fetchClients, deleteDeal, deleteClient } from './firebaseUtils';

const DealFlow = () => {
    const [selectedDeal, setSelectedDeal] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [deals, setDeals] = useState([]);
    const [clients, setClients] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [openAddDealForm, setOpenAddDealForm] = useState(false);
    const [openAddClientForm, setOpenAddClientForm] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getDealsAndClients = async () => {
            setLoading(true); // Start loading
            try {
                const fetchedDeals = await fetchDeals();
                const fetchedClients = await fetchClients();
                setDeals(fetchedDeals);
                setClients(fetchedClients);
            } catch (error) {
                console.error('Error fetching deals or clients:', error);
            } finally {
                setLoading(false); // End loading
            }
        };

        getDealsAndClients();
    }, []);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setSelectedDeal(null);
        setSelectedClient(null);
    };

    const handleDelete = async () => {
        if (deleteId) {
        if (activeTab === 0) {
            await deleteDeal(deleteId);
        } else {
            await deleteClient(deleteId);
        }
        setOpenConfirmDialog(false);
        setDeleteId(null);
        // Refresh data
        const fetchedDeals = await fetchDeals();
        const fetchedClients = await fetchClients();
        setDeals(fetchedDeals);
        setClients(fetchedClients);
        }
    };

    return (
        <Container>
          <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
            <Tab label="Deals" />
            <Tab label="Clients" />
          </Tabs>
          <Box p={3}>
            <Grid container spacing={3}>
              {/* List of Deals or Clients */}
              <Grid item xs={12} md={6}>
                <Paper style={{ padding: '16px' }}>
                  {activeTab === 0 && (
                    <>
                      <h2>Deals</h2>
                      <Button variant="contained" color="primary" onClick={() => setOpenAddDealForm(true)}>
                        Add Deal
                      </Button>
                      <DealList deals={deals} onSelectDeal={setSelectedDeal} />
                    </>
                  )}
                  {activeTab === 1 && (
                    <>
                      <h2>Clients</h2>
                      <Button variant="contained" color="primary" onClick={() => setOpenAddClientForm(true)}>
                        Add Client
                      </Button>
                      <ClientList clients={clients} onSelectClient={setSelectedClient} />
                    </>
                  )}
                </Paper>
              </Grid>
    
              {/* Deal/Client Details */}
              <Grid item xs={12} md={6}>
                <Paper style={{ padding: '16px' }}>
                  {activeTab === 0 && selectedDeal && (
                    <>
                      <DealDetails deal={selectedDeal} />
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setDeleteId(selectedDeal.id);
                          setOpenConfirmDialog(true);
                        }}
                      >
                        Delete Deal
                      </Button>
                    </>
                  )}
                  {activeTab === 1 && selectedClient && (
                    <>
                      <ClientDetails client={selectedClient} />
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setDeleteId(selectedClient.id);
                          setOpenConfirmDialog(true);
                        }}
                      >
                        Delete Client
                      </Button>
                    </>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Box>
    
          {/* Add Deal Form Dialog */}
          <Dialog open={openAddDealForm} onClose={() => setOpenAddDealForm(false)}>
            <DialogTitle>Add New Deal</DialogTitle>
            <DialogContent>
              <AddDealForm onClose={() => setOpenAddDealForm(false)} />
            </DialogContent>
          </Dialog>
    
          {/* Add Client Form Dialog */}
          <Dialog open={openAddClientForm} onClose={() => setOpenAddClientForm(false)}>
            <DialogTitle>Add New Client</DialogTitle>
            <DialogContent>
              <AddClientForm onClose={() => setOpenAddClientForm(false)} />
            </DialogContent>
          </Dialog>
    
          {/* Confirm Delete Dialog */}
          <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              Are you sure you want to delete this {activeTab === 0 ? 'deal' : 'client'}?
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      );
    
};

export default DealFlow;
