import '../Styles/dashStyle.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import Control from './Control';
import DealFlow from './DealFlow';
import Analytics from './Analytics';

function Dashboard() {
    const [activeTab, setActiveTab] = useState('ControlTab');
    const [previousTab, setPreviousTab] = useState(null);
    const [exercise, setExercise] = useState(null);

    const auth = getAuth();

    const handleSignOut = (e) => {
        try {
            signOut(auth);
            console.log("Sign out successful.")
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleExerciseClick = (exercise) => {
        setExercise(exercise);
        setActiveTab('ExerciseTab'); // Switch to Exercise tab
    };

    const handleExerciseChange = (newExercise) => {
        setExercise(newExercise);
    };

    return (
        <div className="dashboard">
            <div className="dashContainer">
                <div className="Nav">
                    <h2 className="logoDash">Alegria Admin Panel</h2>
                    <div className="sideBtns">
                        <button className={activeTab === 'ControlTab' ? 'active' : ''} onClick={() => setActiveTab('ControlTab')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 256 256"><path fill="currentColor" d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28m0 190.61c-6.33-6.09-23-24.41-31.27-54.61h62.54c-8.27 30.2-24.94 48.52-31.27 54.61M94.82 156a140.4 140.4 0 0 1 0-56h66.36a140.4 140.4 0 0 1 0 56ZM128 37.39c6.33 6.09 23 24.41 31.27 54.61H96.73C105 61.8 121.67 43.48 128 37.39M169.41 100h46.23a92.1 92.1 0 0 1 0 56h-46.23a152.7 152.7 0 0 0 0-56m43.25-8h-45a129.4 129.4 0 0 0-29.19-55.4A92.25 92.25 0 0 1 212.66 92m-95.12-55.4A129.4 129.4 0 0 0 88.35 92h-45a92.25 92.25 0 0 1 74.19-55.4M40.36 100h46.23a152.7 152.7 0 0 0 0 56H40.36a92.1 92.1 0 0 1 0-56m3 64h45a129.4 129.4 0 0 0 29.19 55.4A92.25 92.25 0 0 1 43.34 164Zm95.12 55.4a129.4 129.4 0 0 0 29.17-55.4h45a92.25 92.25 0 0 1-74.19 55.4Z"/></svg>
                            Control Panel
                        </button>
                        <button className={activeTab === 'AnalyticsTab' ? 'active' : ''} onClick={() => setActiveTab('AnalyticsTab')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="currentColor" d="M7.73 16.5h1V12h-1zm7.54 0h1V7h-1zm-3.77 0h1V14h-1zm0-4.5h1v-2h-1zm-5.884 8q-.691 0-1.153-.462T4 18.384V5.616q0-.691.463-1.153T5.616 4h12.769q.69 0 1.153.463T20 5.616v12.769q0 .69-.462 1.153T18.384 20zm0-1h12.769q.23 0 .423-.192t.192-.424V5.616q0-.231-.192-.424T18.384 5H5.616q-.231 0-.424.192T5 5.616v12.769q0 .23.192.423t.423.192M5 5v14z"/></svg>
                        Analytics
                        </button>
                        <button className={activeTab === 'CRMTab' ? 'active' : ''} onClick={() => setActiveTab('CRMTab')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="currentColor" d="M21.71 8.71c1.25-1.25.68-2.71 0-3.42l-3-3c-1.26-1.25-2.71-.68-3.42 0L13.59 4H11C9.1 4 8 5 7.44 6.15L3 10.59v4l-.71.7c-1.25 1.26-.68 2.71 0 3.42l3 3c.54.54 1.12.74 1.67.74c.71 0 1.36-.35 1.75-.74l2.7-2.71H15c1.7 0 2.56-1.06 2.87-2.1c1.13-.3 1.75-1.16 2-2C21.42 14.5 22 13.03 22 12V9h-.59zM20 12c0 .45-.19 1-1 1h-1v1c0 .45-.19 1-1 1h-1v1c0 .45-.19 1-1 1h-4.41l-3.28 3.28c-.31.29-.49.12-.6.01l-2.99-2.98c-.29-.31-.12-.49-.01-.6L5 15.41v-4l2-2V11c0 1.21.8 3 3 3s3-1.79 3-3h7zm.29-4.71L18.59 9H11v2c0 .45-.19 1-1 1s-1-.55-1-1V8c0-.46.17-2 2-2h3.41l2.28-2.28c.31-.29.49-.12.6-.01l2.99 2.98c.29.31.12.49.01.6"/></svg>
                        CRM
                        </button>
                    </div>
                    <button className="logoutBtn" onClick={handleSignOut}>
                        Logout
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M11 16L7 12M7 12L11 8M7 12H21M16 16V17C16 17.7956 15.6839 18.5587 15.1213 19.1213C14.5587 19.6839 13.7956 20 13 20H6C5.20435 20 4.44129 19.6839 3.87868 19.1213C3.31607 18.5587 3 17.7956 3 17V7C3 6.20435 3.31607 5.44129 3.87868 4.87868C4.44129 4.31607 5.20435 4 6 4H13C13.7956 4 14.5587 4.31607 15.1213 4.87868C15.6839 5.44129 16 6.20435 16 7V8" stroke="#090516" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                </div>

                <div className="tabs">
                    {activeTab === 'AnalyticsTab' && (
                        <Analytics />
                    )}
                    {activeTab === 'ControlTab' && (
                        <Control />
                    )}
                    {activeTab === 'CRMTab' && (
                        <DealFlow />
                    )}
                </div>

                
            </div>
        </div>
    )
}

export default Dashboard;