import React, { useState, useEffect } from 'react';
import { MenuItem, Select, InputLabel, FormControl, Button, TextField } from '@mui/material';
import { updateClientStatus, addNoteWithVersion, fetchNoteHistory } from './firebaseUtils';

const statuses = ['Active', 'Inactive', 'Prospective', 'On Hold'];

const ClientDetails = ({ client }) => {
  const [status, setStatus] = useState(client.status);
  const [note, setNote] = useState(client.notes || '');
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const fetchHistory = async () => {
      const history = await fetchNoteHistory('clients', client.id);
      setHistory(history);
    };
    fetchHistory();
  }, [client.id]);

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleSaveNote = async () => {
    await addNoteWithVersion('clients', client.id, note);
    const updatedHistory = await fetchNoteHistory('clients', client.id);
    setHistory(updatedHistory);
  };

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    await updateClientStatus(client.id, newStatus);
  };

  return (
    <div>
      <h3>Client Details</h3>
      <p><strong>Name:</strong> {client.name}</p>
      <p><strong>Email:</strong> {client.email}</p>
      <p><strong>Phone:</strong> {client.phone}</p>
      <FormControl fullWidth margin="normal">
        <InputLabel>Status</InputLabel>
        <Select value={status} onChange={handleStatusChange}>
          {statuses.map((statusOption) => (
            <MenuItem key={statusOption} value={statusOption}>
              {statusOption}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Notes"
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        value={note}
        onChange={handleNoteChange}
      />
      <Button variant="contained" onClick={handleSaveNote} style={{marginBlock: '20px'}}>
        Save Note
      </Button>
    </div>
  );
};

export default ClientDetails;
