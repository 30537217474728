import React from 'react';
import { List, ListItem, ListItemText, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { useState } from 'react';
import { updateDealStatus } from './firebaseUtils';
import { formatCurrency } from './utils';
import '../Styles/utilStyles.css';

const statuses = ['Pending', 'In Progress', 'Completed', 'Closed'];

const DealList = ({ deals, onSelectDeal }) => {
  const [selectedDealId, setSelectedDealId] = useState(null);
  const [status, setStatus] = useState('');
  const [sortedDeals, setSortedDeals] = useState(deals);
  const [sortOrder, setSortOrder] = useState('asc');
  const [expandedDealId, setExpandedDealId] = useState(null);

  const sortDealsByStatus = () => {
    const sorted = [...deals].sort((a, b) => {
      const statusA = a.status || ''; // Fallback to an empty string if status is undefined
      const statusB = b.status || ''; // Fallback to an empty string if status is undefined
  
      if (sortOrder === 'asc') {
        return statusA.localeCompare(statusB);
      } else {
        return statusB.localeCompare(statusA);
      }
    });
    setSortedDeals(sorted);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };  

  const handleStatusChange = async (dealId, event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    await updateDealStatus(dealId, newStatus);
    setSelectedDealId(null); // Optionally close the dropdown after selection
  };

  const handleDealClick = (deal) => {
    if (expandedDealId === deal.id) {
      // If the clicked deal is already expanded, collapse it
      setExpandedDealId(null);
    } else {
      // Otherwise, expand the clicked deal
      setExpandedDealId(deal.id);
      onSelectDeal(deal);
    }
  };

  return (
    <div>
      <button className="sortBy" onClick={sortDealsByStatus}>
        Sort by Status ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
      </button>
      <List>
        {sortedDeals.map(deal => (
          <ListItem button key={deal.id} onClick={() => handleDealClick(deal)}>
            <ListItemText primary={deal.name} secondary={`Amount: ${formatCurrency(deal.amount)}`} />
            <FormControl variant="outlined" style={{ minWidth: 120 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={selectedDealId === deal.id ? status : deal.status}
                onChange={(event) => handleStatusChange(deal.id, event)}
                onClick={() => setSelectedDealId(deal.id)}
              >
                {statuses.map(statusOption => (
                  <MenuItem key={statusOption} value={statusOption}>
                    {statusOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default DealList;
