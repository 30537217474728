import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc, orderBy, query, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

export const addDeal = async (deal) => {
  try {
    const docRef = await addDoc(collection(db, 'deals'), deal);
    console.log('Deal added with ID:', docRef.id);
  } catch (e) {
    console.error('Error adding deal:', e);
  }
};

export const addClient = async (client) => {
    try {
        const docRef = await addDoc(collection(db, 'clients'), client);
        console.log('Client added with ID:', docRef.id);
    } catch (e) {
        console.error('Error adding client:', e);
    }
};

export const fetchDeals = async () => {
    const querySnapshot = await getDocs(collection(db, 'deals'));
    const deals = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return deals;
};

export const fetchClients = async () => {
    const querySnapshot = await getDocs(collection(db, 'clients'));
    const clients = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return clients;
};

export const deleteDeal = async (dealId) => {
    try {
      await deleteDoc(doc(db, 'deals', dealId));
      console.log('Deal deleted with ID:', dealId);
    } catch (e) {
      console.error('Error deleting deal:', e);
    }
};

export const deleteClient = async (clientId) => {
    try {
      await deleteDoc(doc(db, 'clients', clientId));
      console.log('Client deleted with ID:', clientId);
    } catch (e) {
      console.error('Error deleting client:', e);
    }
};

export const updateDealStatus = async (dealId, status) => {
    try {
      const dealRef = doc(db, 'deals', dealId);
      await updateDoc(dealRef, { status });
      console.log('Deal status updated:', status);
    } catch (e) {
      console.error('Error updating deal status:', e);
    }
};
  
export const updateClientStatus = async (clientId, status) => {
    try {
      const clientRef = doc(db, 'clients', clientId);
      await updateDoc(clientRef, { status });
      console.log('Client status updated:', status);
    } catch (e) {
      console.error('Error updating client status:', e);
    }
};

export const addNoteWithVersion = async (type, id, newNote) => {
    const docRef = doc(db, type, id);
    const noteHistoryRef = collection(docRef, 'notesHistory');
    
    // Fetch current notes and version
    const docSnap = await getDoc(docRef);
    const notes = docSnap.data().notes || '';
    const currentVersion = (await getDocs(query(noteHistoryRef, orderBy('version', 'desc')))).docs.length + 1;
  
    // Update note and add to history
    await updateDoc(docRef, {
      notes: newNote
    });
  
    await addDoc(noteHistoryRef, {
      version: currentVersion,
      note: newNote,
      timestamp: new Date()
    });
  };
  
// Fetch notes and their history
export const fetchNoteHistory = async (type, id) => {
const docRef = doc(db, type, id);
const noteHistoryRef = collection(docRef, 'notesHistory');
const q = query(noteHistoryRef, orderBy('version', 'desc'));
const querySnapshot = await getDocs(q);

return querySnapshot.docs.map(doc => doc.data());
};

